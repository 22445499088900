import { LoginForm, SignUpForm } from "@/components/Pages/LoginPage";
import { useAppSelector } from "@/hooks/useRedux";
import { loginInfo } from "@/store/slices/loginSlice";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";

export default function Login() {
  const loginState = useAppSelector(loginInfo);
  const router = useRouter();

  useEffect(() => {
    if (loginState.isLogin === true) {
      router.push("/category");
    }
  }, [loginState.isLogin, router]);

  return (
    <>
      <Head>
        <title>Login & Signup | Priceworth Furniture</title>
        <meta property="og:title" content="Login & Signup" key="title" />
      </Head>
      <div className="py-8 lg:py-20 container h-full">
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1 flex justify-center py-12 border-b lg:border-r lg:border-b-0">
            <LoginForm />
          </div>
          <div className="flex-1 flex py-12 justify-center">
            <SignUpForm />
          </div>
        </div>
      </div>
    </>
  );
}
